import Countdown from '/components/Countdown';
import CountrySelector from '../CountrySelector';
import DiscountCodeBannerText from './DiscountCodeBannerText';
import Link from 'next/link';
import Typography from '/components/Typography';
import { observer } from 'mobx-react-lite';
import { useDiscountCodeStore } from '../../state/discount-code-context';
import { useGlobalStore } from '../../state/global-context';
import { useEffect, useState } from 'react';

const SitewideBanner = () => {
	const globalStore = useGlobalStore();
	const discountCodeStore = useDiscountCodeStore();
	const currentPromotion = globalStore.getPromotionalConfig();
	const [discountCodeConfig, setDiscountCodeConfig] = useState();
	const currentCountryCode = globalStore.getCurrentCountryName();

	useEffect(() => {
		const codeConfig = discountCodeStore.getCurrentDiscountCodeConfig();
		setDiscountCodeConfig(codeConfig);
	}, [discountCodeStore?.currentDiscountCode]);

	if (currentPromotion && currentPromotion?.sitewideBanner) {
		return (
			<div
				className="flex items-center justify-between md:py-0 md:h-12 text-sm md:px-4 text-center"
				style={{
					backgroundColor: currentPromotion.sitewideBanner.colorPrimary,
					color: '#d9a54f',
				}}>
				<CountrySelector className="hidden md:flex" />

				<div className="flex-grow flex flex-col items-center justify-center">
					<div>
						<Typography className="text-center">{currentPromotion.sitewideBanner.text}</Typography>

						<Link href={currentPromotion.termsHref}>
							<Typography className="underline cursor-pointer text-center pl-1">
								T&amp;Cs.
							</Typography>
						</Link>
					</div>

					<Typography
						className="text-[#F3E7D4] font-bold uppercase !tracking-wider text-center"
						component="p"
						variant="body">
						Ends In <Countdown countdownDate={currentPromotion.dateEnd} />
					</Typography>
				</div>

				<div className="hidden md:block"></div>
			</div>
		);
	}

	if (discountCodeConfig) {
		return (
			<div className="bg-primary px-6 flex items-center align-middle text-white md:px-0 h-10 lg:h-12">
				<CountrySelector className="hidden lg:flex pl-5" />
				<Typography
					component="div"
					className="font-semibold text-center m-auto flex gap-1"
					variant="small">
					<DiscountCodeBannerText
						bannerTextKey="bannerTextSecondary"
						discountCodeConfig={discountCodeConfig}
					/>
					{discountCodeConfig?.bannerHref && (
						<Link href={discountCodeConfig.bannerHref}>
							<span className="underline cursor-pointer">Get started.</span>
						</Link>
					)}
				</Typography>
			</div>
		);
	}

	return (
		<div className="bg-primary px-6 flex items-center align-middle justify-between md:justify-between text-white md:px-0 h-10 lg:h-12">
			<CountrySelector className="hidden lg:flex pl-5" />
			{currentCountryCode === 'United States' && (
				<Typography className="text-center font-fontFamily" variant="small">
					Save up to 49% plus get free shipping when you{' '}
					<Link href="/pages/all-subscriptions">
						<span className="underline cursor-pointer">subscribe</span>
					</Link>
					.
				</Typography>
			)}
			{currentCountryCode !== 'United States' && (
				<Typography className="text-center font-fontFamily" variant="small">
					New and Improved Shipping to {currentCountryCode}
				</Typography>
			)}
			<span />
		</div>
	);
};
export default observer(SitewideBanner);
