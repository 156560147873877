import axios from 'axios';
import { formatSearchspringResponse } from './formatter';
const COLLECTION_BIKINI = 'womens-bikini-underwear';
const COLLECTION_BOYS_UNDERWEAR = 'boys-underwear';
const COLLECTION_BOXERS = 'singles-ball-hammock-pouch-underwear';
const COLLECTION_BOXERS_BRIEF = 'ball-hammock-briefs';
const COLLECTION_BOXERS_COOLING_FLY = 'ball-hammock-pouch-cooling-underwear';
const COLLECTION_BOXERS_FLY = 'mens-ball-hammock-pouch-underwear-with-fly';
const COLLECTION_BOXERS_LOOSE = 'pouchless-mens-boxers';
const COLLECTION_BOXERS_LONG = 'mens-long-leg-boxer-briefs';
const COLLECTION_BOXERS_TRUNK = 'ball-hammock-trunk-underwear';
const COLLECTION_BOYSHORT = 'womens-boyshort-underwear';
const COLLECTION_CHEEKY = 'cheeky-underwear';
const COLLECTION_LONG_JOHN = 'long-john-underwear';
const COLLECTION_MENS_LOUNGE_HOODIE = 'mens-goldilocks-hoodies-never-too-tight-loose-hot-or-cold';
const COLLECTION_MENS_LOUNGE_JOGGER = 'mens-hoodies-and-sweatpants';
const COLLECTION_MENS_TSHIRTS = 'mens-everyman-t-shirts';
const COLLECTION_SLEEP_MENS_BOTTOMS =
	'sleepdeep-mens-pajamas-bottoms-super-stupid-soft-micromodal-pajama-bottoms-for-men';
const COLLECTION_SLEEP_MENS_TOPS =
	'sleepdeep-mens-pajamas-tops-super-stupid-soft-micromodal-pajama-tops-for-men';
const COLLECTION_SLEEP_WOMENS_DRESS = 'sleepdeep-sleep-dresses';
const COLLECTION_TYPE_SLEEP_WOMENS_SETS = 'sleepdeep-pajamas-for-women';
const COLLECTION_THONG = 'womens-thong-underwear';
const COLLECTION_THONGS_MODAL = 'modal-thong-underwear-for-women';
const COLLECTION_WOMENS_BOXER = 'womens-boxer-briefs';
const COLLECTION_WOMENS_LOUNGE_HOODIE = 'womens-sweartshirt-and-sweatpants-sets';
const COLLECTION_WOMENS_LOUNGE_JOGGER =
	'womens-goldilocks-joggers-never-too-tight-loose-hot-or-cold';

const collections = {
	bikini: COLLECTION_BIKINI,
	boxers: COLLECTION_BOXERS,
	'boxers brief': COLLECTION_BOXERS_BRIEF,
	'boxers cooling': COLLECTION_BOXERS_COOLING_FLY,
	'boxers fly': COLLECTION_BOXERS_FLY,
	'boxers long fly': COLLECTION_BOXERS_LONG,
	'boxers loose': COLLECTION_BOXERS_LOOSE,
	'boxers trunk': COLLECTION_BOXERS_TRUNK,
	'boys underwear': COLLECTION_BOYS_UNDERWEAR,
	boyshort: COLLECTION_BOYSHORT,
	bralettes: 'bralettes',
	'bralettes cooling': 'cooling-thong-underwear-bralette',
	cheeky: COLLECTION_CHEEKY,
	'long johns': COLLECTION_LONG_JOHN,
	'lounge mens hoodie': COLLECTION_MENS_LOUNGE_HOODIE,
	'lounge mens jogger': COLLECTION_MENS_LOUNGE_JOGGER,
	"men's shorts": 'ball-hammock-athletic-shorts',
	"men's t-shirts": COLLECTION_MENS_TSHIRTS,
	'sleep mens tops': COLLECTION_SLEEP_MENS_TOPS,
	'sleep mens bottoms': COLLECTION_SLEEP_MENS_BOTTOMS,
	'sleep womens dress': COLLECTION_SLEEP_WOMENS_DRESS,
	'socks ankle': 'heel-hammock-ankle-socks',
	'socks crew': 'heel-hammock-crew-socks',
	thongs: COLLECTION_THONG,
	'thongs cooling': 'cooling-thong-underwear-bralette',
	'thongs modal': COLLECTION_THONGS_MODAL,
	'womens boxer': COLLECTION_WOMENS_BOXER,
	'lounge womens hoodie': COLLECTION_WOMENS_LOUNGE_HOODIE,
	'lounge womens jogger': COLLECTION_WOMENS_LOUNGE_JOGGER,
	'sleep womens sets': COLLECTION_TYPE_SLEEP_WOMENS_SETS,
};

const validShopByPrintCollections = [
	COLLECTION_BIKINI,
	COLLECTION_BOXERS,
	COLLECTION_BOXERS_BRIEF,
	COLLECTION_BOXERS_COOLING_FLY,
	COLLECTION_BOXERS_FLY,
	COLLECTION_BOXERS_LONG,
	COLLECTION_BOXERS_TRUNK,
	COLLECTION_BOYSHORT,
	COLLECTION_BOYS_UNDERWEAR,
	COLLECTION_CHEEKY,
	COLLECTION_LONG_JOHN,
	COLLECTION_MENS_TSHIRTS,
	COLLECTION_THONG,
	COLLECTION_THONGS_MODAL,
	COLLECTION_WOMENS_BOXER,
];

const getSimilarPrints = async (product, print, size) => {
	const productType = product?.product_type || product?.shopifyProductType || product?.productType;
	const productTypeLower = productType?.toLowerCase();

	const invalidProductTypeForPrintFiltering = [
		'lounge mens hoodie',
		'lounge mens jogger',
		'lounge womens hoodie',
		'lounge womens jogger',
		'sleep mens bottoms',
		'sleep mens tops',
		'sleep womens dress',
		'sleep womens sets',
		"men's t-shirts",
	].includes(productTypeLower);

	const params = new URLSearchParams();
	params.append('resultsFormat', 'native');
	params.append('siteId', process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID);
	params.append('resultsPerPage', '20');

	if (print && !invalidProductTypeForPrintFiltering) {
		params.append('filter.tags', print);
	}

	if (productTypeLower) {
		params.append('filter.product_type', productType);
	}

	if (size) {
		params.append('filter.ss_size', size);
	}
	params.append('bgfilter.mfield_denver_inventory_total_product_quantity.low', '1');

	try {
		const response = await axios.get(
			`https://${
				process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID
			}.a.searchspring.io/api/search/search.json?${params.toString()}`,
		);

		const formattedResponse = formatSearchspringResponse(response.data);

		const validResults = formattedResponse.results.filter((result) => {
			if (productType?.toLowerCase() === "men's shorts") {
				const length = product.handle.split('-shorts-');
				return result.handle.includes(length && length.length > 0 ? length[1] : null);
			}
			return result.shopifyProductType === productTypeLower;
		});
		return validResults;
	} catch (error) {
		console.error('Searchspring API Error:', error);
		return [];
	}
};

const getCollection = async (collectionHandle, hitsPerPage = 40) => {
	const params = new URLSearchParams();
	params.append('resultsFormat', 'native');
	params.append('siteId', process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID);
	params.append('resultsPerPage', hitsPerPage.toString());
	params.append('bgfilter.collection_handle', collectionHandle);

	try {
		const response = await axios.get(
			`https://${
				process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID
			}.a.searchspring.io/api/search/search.json?${params.toString()}`,
		);

		return formatSearchspringResponse(response.data);
	} catch (error) {
		console.error('Searchspring API Error:', error);
		return { facets: {}, results: [], pagination: { totalResults: 0, totalPages: 1 } };
	}
};

const getRecommendedProducts = async (sourceProduct) => {
	const params = new URLSearchParams();
	params.append('resultsFormat', 'native');
	params.append('siteId', process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID);
	params.append('filter.shopifyProductType', sourceProduct.productType);

	try {
		const response = await axios.get(
			`https://${
				process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID
			}.a.searchspring.io/api/search/search.json?${params.toString()}`,
		);

		const formattedResponse = formatSearchspringResponse(response.data);
		return formattedResponse.results.filter((result) => result.handle !== sourceProduct.handle);
	} catch (error) {
		console.error('Searchspring API Error:', error);
		return [];
	}
};

export {
	collections,
	getCollection,
	getRecommendedProducts,
	getSimilarPrints,
	validShopByPrintCollections,
};
